
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { AreaObject } from '@/store/modules/IVE/AreasModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ive-areas-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasIveValuesUpdating'), [
          translate('ive'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_IVE_AREA,
        route.params.id
      );
      const area = ref<AreaObject>(data.data);
      if (area.value.customUpdatedAt) {
        const dateString = new Date(area.value.customUpdatedAt);

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        area.value.customUpdatedAt = `${year}-${month}-${day}`;
      } else {
        area.value.customUpdatedAt = '';
      }
      console.log(area.value.customUpdatedAt);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const basePrice = (val) => {
        area.value.basePrice = Number(val.price) * Number(val.baseSize);
      };

      const price = (val) => {
        area.value.price = Number(val.basePrice) / Number(val.baseSize);
      };

      const areaSchema = Yup.object().shape({
        price: Yup.number()
          .required(() => translate('AREA_VALUE_IS_REQUIRED_FIELD'))
          .label('Price'),
        basePrice: Yup.number()
          .required(() => translate('PRICE_VALUE_IS_REQUIRED_FIELD'))
          .label('Basic Price'),
      });

      const onSubmitUpdate = async (values) => {
        delete values.AreasName;
        delete values.baseSize;
        values.price = Number(values.price);
        values.basePrice = Number(values.basePrice);

        if (values.isSeaView == true) values.isSeaView = true;
        else values.isSeaView = false;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_IVE_AREA, {
          id: area.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'ive-areas-listing' });
          });
        }
      };
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'ive' });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        areas: computed(() => store.getters.allListAreas),
        submitButton,
        translate,
        basePrice,
        price,
        areaSchema,
        goBack,
        area,
        can,
      };
    },
  });
